.menu_wrapper{
    position: absolute;
    opacity: 0;
    visibility: hidden;
    .menu{
        background-color: #252529;
        position: relative;
        z-index: 101;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        .item{
            padding: 10px;
            display: flex;
            gap: 10px;
            align-items: center;
            color: #C6C6CC;
            border-bottom: 1px #C6C6CC solid;
            cursor: pointer;
            &:first-child{
                border-radius: 5px 5px 0px 0px;
            }
            &:last-child{
                border-radius: 0px 0px 5px 5px;
            }
            &:hover{
                background-color: #303031;;
            }
            img{
                width: 24px;
                height: 24px;
            }
            &:last-child{
                color: #FF4040;
                border-bottom: none;
            }
        }
    }
    .close_wrapper{
        width: 100vw;
        height: 100vh;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
    }
}