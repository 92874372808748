@import '../../../index.scss';
.pages{
    width: 100%;
    header{
        width: 100%;
        display: flex;
        gap: 10px;
        border-bottom: 1px solid #EC722E;
        padding: 15px;
        flex-wrap: wrap;
        
        .page_item{
            padding: 10px 5px 10px 20px;
            background-color: #333849;
            color: #000;
            border-radius: 5px;
            cursor: pointer;
            font-size: 18px;
            transition: .3s;
            position: relative;
            color: #FFF;
            display: flex;
            gap: 10px;
            border: 1px transparent solid;
            user-select: none;
            p{
                white-space: nowrap;
                &:hover{
                    transition: .2s;
                    color: #EC722E;
                }
            }
            .icon_wrapper{
                width: 24px;
                height: 24px;
            }
        }
        .active{
            color: #FFF;
            border: 1px #EC722E solid;
            color: #EC722E;
        }
        .add_page{
            border: 1px #EC722E solid;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #EC722E;
            width: 50px;
            font-size: 20px;
            user-select: none;
            cursor: pointer;
            transition: .3s;
            &:hover{
                color: #FFF;
                background-color: #EC722E;
            }
        }
        .page_tools{
            float: right;
            flex: 1 0 auto;
            display: flex;
            gap: 10px;
            justify-content: flex-end;
            align-items: center;
            padding-right: 15px;
            .tools_icon{
                cursor: pointer;
                &:hover{
                    svg{
                        pointer-events: none;
                        height: 20px;
                        width: 25px;
                        transition: .3s;
                        &:hover{
                            display: none;
                            path{
                                transition: .3s;
                                fill: #EC722E !important;
                            }
                        }
                    }
                }
                svg{
                    pointer-events: none;
                    height: 20px;
                    width: 25px;
                    transition: .3s;
                    &:hover{
                        path{
                            transition: .3s;
                            fill: #EC722E !important;
                        }
                    }
                }
            }
        }
    }
    .content{
        display: flex;
        padding: 10px;
        .left{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 50px;
            .section{
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 15px;
                gap: 15px;
                background-color: #20232E;
                border-radius: 20px;
                .header{
                    text-align: center;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 20px;
                    p{
                        font-size: 20px;
                        span{
                            color: green;
                        }
                    }
                    .tools{
                        @include tools;
                    }
                }
                .block{
                    // border: 1px #E5E0EB solid;
                    width: 100%;
                    display: flex;
                    background-color: #333849;
                    flex-direction: column;
                    justify-content: space-between;
                    font-size: 14px;
                    header{
                        display: flex;
                        justify-content: space-between;
                        .tools{
                            @include tools;
                        }
                        .info_block{
                            display: flex;
                            gap: 20px;
                            .header_item{
                                font-size: 16px;
                                .title{
                                    color: #EC722E;
                                }
                                .value{
                                    color: green;
                                }
                            }
                        }
                    }
                    .content{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        // gap: 15px;
                        padding: 0;
                        .lang_block{
                            @include lang_block;
                        }
                        .text_block{
                            padding: 10px 20px 20px 20px;
                        }
                        .img_wrapper{
                            height: 200px;
                            width: 200px;
                            margin: 0 auto;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            img{
                                max-height: 100%;
                            }
                        }
                    }
                    footer{
                        display: flex;
                        padding: 10px 10px;
                        background-color: #999999;
                        margin-top: 20px;
                    }
                }
                .images_content{
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    margin: 17px 0;
            
                    .header{
                        display: flex;
                        gap: 3px;
                        justify-content: center;
            
                        .language{
                            text-transform: uppercase;
                        }
                    }

                    .color{
                        color: rgb(151, 255, 151);
                    }
            
                    .image_item_wrapper{
                        display: flex;
                        flex-direction: column;
            
                        .image_wrapper{
                            width: 200px;
                            height: 150px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            box-shadow: 1px 1px 13px -7px #000;
                        }
                    }
                }
                .tools{
                    // @include tools;

                }
            }
        }
        .right{
            // display: flex;
            display: none;
            flex-direction: column;
            gap: 20px;
            width: 20%;
            h3{
                text-align: center;
            }
            .settings_block{
                display: flex;
                align-items: flex-end;
                gap: 15px;
                border-bottom: 1px solid rgb(167, 167, 167);
                padding: 15px 0;
                .input_wrapper{
                    flex: 1 0 auto;
                    .title{
                        font-size: 14px;
                    }
                    input{
                        height: 30px;
                        width: 100%;
                    }
                }
            }
            .button{
                width: fit-content;
                margin: 0 auto;
                font-size: 14px;
                padding: 10px 15px;
                border: 1px #ccc solid;
                cursor: pointer;
            }
        }
    }
}