@import '../../../index.scss';

.item{
    background-color: #20232E;
    // background-color: #556dc2;
    border: 1px #333849 solid;
    padding: 0px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    header{
        padding: 10px;
        display: flex;
        justify-content: center;
        position: relative;
        span{
            font-size: 20px;
            color: #EC722E;
        }
        .tools{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include tools;
        }
    }
}