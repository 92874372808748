.modal{
    width: 70% !important;  
}
.image_items_wrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 17px;

    .image_item_wrapper{
        display: flex;
        flex-direction: column;
        gap: 5px;

        .image_wrapper{
            width: 200px;
            height: 150px;
            box-shadow: 1px 1px 13px -7px #000;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;

            .change_input{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100% !important;
                opacity: 0;
                cursor: pointer;
            }
        }

        .header{
            display: flex;
            gap: 7px;
            justify-content: center;
            align-items: center;

            .lang{
                text-transform: uppercase;
            }
            .default_wrapper{
                display: flex;
                gap: 7px;
                align-items: center;

                input{
                    width: 15px !important;
                    height: 15px !important;
                }
            }
        }
    }
}