@import '../../../index.scss';

.item{
    background-color: #20232E;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 20px;
    box-shadow: 1px 1px 13px -7px #000;
    margin: 15px auto;
    header{
        padding: 10px;
        display: flex;
        justify-content: center;
        position: relative;
        span{
            font-size: 20px;
            color: #EC722E;
        }
        .tools{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            @include tools;
        }
    }
}